import React from 'react';
import Layout from '../components/Layout';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { Link, Button } from 'gatsby-material-ui-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const IndexPage = ({ data }) => {
    return (
        <>
            <Helmet>
                <title>{data.site.siteMetadata.title}</title>
            </Helmet>
            <Layout>
                <SiteTitle variant="h4" component="h1">
                    Programátorské školenia
                </SiteTitle>
                <Box>
                    <TextContainer>
                        <HeroText>
                            Ahoj, volám sa <Link to="https://milan.perunhq.org/">Milan Herda.</Link>{' '}
                            Som JavaScriptový a PHP programátor a okrem toho, že sám programujem,
                            tak programovanie aj učím. Viem vám ponúknuť{' '}
                            <Link to="/ponuka">niekoľko školení na rôzne témy.</Link>
                        </HeroText>
                        <HeroText>
                            Učím iba to, čo aj sám používam, čo sa mi v praxi osvedčilo a čomu
                            verím.
                        </HeroText>
                        <HeroText>
                            Školenia robím formou workshopu, kde účastníci programujú veci, ktoré
                            sme si ukázali, ale zároveň dostávajú aj úlohy, kde musia práve
                            nadobudnuté znalosti hneď použiť. Prechádzam si s nimi ich riešenia a
                            rozoberáme výhody a nevýhody.
                        </HeroText>
                        <HeroText>
                            Výhodou takejto formy školenia je pomerne vysoká angažovanosť a
                            efektívnosť, pretože si účastníci všetko priamo vyskúšajú. Nevýhodou je
                            len obmedzený počet ľudí, pre ktorý je možné túto formu plnohodnotne
                            zvládnuť.
                        </HeroText>
                        <HeroText>
                            Školenie vieme zorganizovať vo vašich priestoroch alebo online. Buď
                            podľa vašich preferencií alebo pandemickej situácie.
                        </HeroText>
                    </TextContainer>
                    <Box>
                        <Button
                            variant="contained"
                            to="/ponuka"
                            size="large"
                            endIcon={<ChevronRightIcon />}
                        >
                            Pozrite si zoznam školení
                        </Button>
                    </Box>
                </Box>
            </Layout>
        </>
    );
};

const SiteTitle = styled(Typography)(({ theme }) => ({
    paddingTop: theme.spacing(2),
})) as typeof Typography;

const TextContainer = styled(Box)(({ theme }) => ({
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
})) as typeof Box;

const HeroText = styled(Typography)(() => ({
    fontSize: '1.25rem',
})) as typeof Typography;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;

export default IndexPage;
